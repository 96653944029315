import { WithContext, Thing, Graph } from 'schema-dts';

type JsonLD = WithContext<Thing> | WithContext<Thing>[] | Graph | null;

const scriptRu: JsonLD = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'Oskelly Group',
  url: 'https://oskelly.ru/',
  logo: 'https://static.oskelly.ru/images/icons/insta_logo.png?v=2.1.2_c0753d93593d6441d0dd4f9eaecf0d8f',
  image: 'https://static.oskelly.ru/images/icons/insta_img_1.jpg?v=2.1.2_0a83dbe2155b3a0373b2dc3eb9a5a5ba',
  description:
    'Первый сервис для покупки и перепродажи брендовых вещей в России. Бутики и частные продавцы размещают у нас новую и почти новую брендовую одежду со скидками до -90%.\n\nКаждый день мы добавляем 100+ новых товаров, а наши эксперты собирают лучшие из них в специальные подборки.',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Варшавское шоссе, 9с28',
    postOfficeBoxNumber: 'info@oskelly.ru',
    addressLocality: 'Москва',
    addressRegion: 'Москва',
    postalCode: '117105',
    addressCountry: 'Россия',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '55.737054',
    longitude: '37.563080',
  },
  hasMap: 'https://goo.gl/maps/x94wA7cnGmzmkt4v9',
  openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su -',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '8 (800) 707-53-08',
    contactType: 'shop',
  },
};

const scriptEn: JsonLD = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'Oskelly Group',
  url: 'https://oskelly.co/',
  logo: 'https://static.oskelly.ru/images/icons/insta_logo.png?v=2.1.2_c0753d93593d6441d0dd4f9eaecf0d8f',
  image: 'https://static.oskelly.ru/images/icons/insta_img_1.jpg?v=2.1.2_0a83dbe2155b3a0373b2dc3eb9a5a5ba',
  description: 'Multi-service platform for managing a luxury wardrobe, offering the ability to buy and sell for both private sellers and professional partners. Every day, we add over 100 new items, and our experts curate the best of them into special selections – from limited and rare pieces, and new collections from global brands, to almost-new items with discounts of up to 90%.',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'DUBAI COMMERCITY LLC of PO Box 491',
    postOfficeBoxNumber: 'info@oskelly.co',
    addressLocality: 'Dubai',
    addressRegion: 'Dubai',
    postalCode: 'BCB2-215',
    addressCountry: 'United Arab Emirates',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '55.737054',
    longitude: '37.563080',
  },
  hasMap: 'https://goo.gl/maps/x94wA7cnGmzmkt4v9',
  openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su -',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+97146086488',
    contactType: 'shop',
  },
};

export { scriptRu, scriptEn };
